import Stepper, { StepType } from 'components/stepper'
import { useFormik } from 'formik'
import { map } from 'lodash'
import { useAuth } from 'modules/auth/context/useAuth'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createQuote, editQuote, getQuoteDetail, ICreateQuote } from '../queries/quotes'

import ConfigurationSelection from './steps/ConfigurationSelection'
import QuoteInformation from './steps/QuoteInformation'
import QuoteReview from './steps/QuoteReview'

const CreateQuote = () => {
    const [activeStep, setActiveStep] = useState<number>(0)
    const { user } = useAuth()
    const { userExtra } = useAuth()
    const { quoteId } = useParams()

    const navigate = useNavigate()

    const create = useMutation(
        'createQuote',
        (formData: ICreateQuote) =>
            quoteId ? editQuote(quoteId, formData) : createQuote(formData, user.id),
        {
            onSuccess: (data: any) => {
                toast(data?.message || `Quote created successfully`, { type: 'success' })
                navigate('/quotes')
            },
            onError: (error: any) => {
                toast(error?.message || `Failed to update information`, { type: 'error' })
            },
        },
    )

    const validationSchemas = [
        Yup.object().shape({
            quotation_name: Yup.string().required('Name is required'),
            quotation_for: Yup.string().required('Quotation For is required'),
            user:
                userExtra.role_id === 4 ? Yup.string().required('User is required') : Yup.string(),
        }),
        Yup.object().shape({
            configurations: Yup.array()
                .min(1, 'Atleast one configuration is required')
                .required('Configuration is required'),
            template: Yup.string().required('Template is required'),
        }),
    ]

    const formik = useFormik({
        initialValues: {
            quotation_name: '',
            quotation_for: '',
            configurations: [],
            template: '',
            user: user?.id || '',
            status: '',
        },
        validationSchema: validationSchemas[activeStep],
        validateOnChange: false, // Disable automatic validation on change
        validateOnBlur: false, // Disable automatic validation on blur
        onSubmit: async (values: ICreateQuote) => {
            if (activeStep === 2) {
                create.mutate(values)
            } else {
                const isValid = await formik.validateForm()
                if (Object.keys(isValid).length === 0) {
                    setActiveStep(activeStep + (quoteId ? 2 : 1))
                }
            }
        },
    })

    useQuery(
        ['quoteDetail', quoteId],
        () =>
            getQuoteDetail(
                quoteId || '',
                user.id,
                userExtra.is_support,
                userExtra.is_dealer,
                userExtra.is_manager,
                userExtra.role_id,
            ),
        {
            onSuccess: data => {
                formik.setFieldValue('quotation_name', data?.data?.quote[0].quotation_name || '')
                formik.setFieldValue(
                    'quotation_for',
                    data?.data?.quote[0].quotation_for || user?.id || '',
                )
                formik.setFieldValue('user', data?.data?.quote[0].user_id || '')
                formik.setFieldValue(
                    'configurations',
                    map(data?.data?.configurations, configuration => {
                        return {
                            label: configuration.name,
                            value: configuration.id,
                        }
                    }) || [],
                )
                formik.setFieldValue('template', data?.data?.quote[0].template_id || '')
                formik.setFieldValue('status', data?.data?.quote[0].status || '')
            },
            retryOnMount: true,
            enabled: !!quoteId,
        },
    )

    const steppers: StepType[] = [
        {
            id: 0,
            step: 0,
            label: 'Quote Information',
            component: <QuoteInformation formik={formik} />,
        },
        {
            id: 1,
            step: 1,
            label: 'Selection',
            hidden: !!quoteId,
            component: (
                <ConfigurationSelection formik={formik} changeStep={step => setActiveStep(step)} />
            ),
        },
        {
            id: 2,
            step: quoteId ? 1 : 2,
            label: 'Review & Confirmation',
            component: (
                <QuoteReview
                    formik={formik}
                    changeStep={(step: number) => setActiveStep(quoteId ? 0 : step)}
                    isSubmitting={create.isLoading}
                />
            ),
        },
    ]

    return (
        <div className="flex flex-col gap-3 w-full">
            <Stepper
                steppers={steppers}
                activeStep={steppers[activeStep]}
                changeActiveStep={(step: number) => setActiveStep(step)}
            />
            <div>{steppers[activeStep].component}</div>
        </div>
    )
}

export default CreateQuote
