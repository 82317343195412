import React, { useState } from 'react'

import { useQueries } from 'react-query'
import * as AntD from 'antd'
import Select from 'react-select'

import { toast } from 'react-toastify'

import Button from 'components/button'
import { map } from 'lodash'
import { getAllConfigurationTemplate, getAllSelectedTemplate } from 'modules/quotes/queries/quotes'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'modules/auth/context/useAuth'
import Loading from '../../../../components/loading'

const size = 'large'

interface ConfigurationSelectionProps {
    changeStep: (step: number) => void
    formik: any
}

const ConfigurationSelection = (props: ConfigurationSelectionProps) => {
    const { changeStep, formik } = props
    const navigate = useNavigate()
    const { user, userExtra } = useAuth()

    const [configurationList, setConfigurationList] = useState<[]>([])
    const [templates, setTemplates] = useState<[]>([])

    const quoteInfos = useQueries([
        {
            queryKey: ['templateList'],
            queryFn: () => getAllSelectedTemplate(userExtra.role_id),
            onSuccess: (data: any) => {
                const templateList = data?.data.selected_templates.map(
                    (template: { template_name: string; template_id: string }) => {
                        return {
                            label: template.template_name,
                            value: template.template_id,
                        }
                    },
                )
                setTemplates(templateList)
                formik.setFieldValue('template', templateList[0].value)
            },
            retryOnMount: true,
            refetchOnWindowFocus: true,
        },
        {
            queryKey: ['configurationList', formik.values.user],
            queryFn: () => getAllConfigurationTemplate(formik.values.user || user.id),
            onSuccess: (data: any) => {
                if (data?.data.length === 0) {
                    toast('No configuration found for this user', { type: 'error' })
                }
                setConfigurationList(data?.data || [])
            },
            retryOnMount: true,
            refetchOnWindowFocus: true,
        },
    ])

    const isLoading = quoteInfos.some(result => result.isLoading)

    if (isLoading) {
        return (
            <div className="flex justify-center items-center bg-white px-8 py-4 rounded-xl gap-4">
                <Loading />
            </div>
        )
    }

    return (
        <div className="bg-white py-8 px-8 rounded-lg flex flex-col gap-8">
            <h1 className="text-3xl text-center">Select Configurations</h1>
            <div className="flex flex-col gap-8 justify-center align-center items-center">
                {/* configurations */}
                <div className="flex items-center align-center gap-16 flex-1 w-full justify-center">
                    {configurationList.length ? (
                        <AntD.Form.Item
                            name="configuration"
                            className="flex flex-col w-[45%]"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <div>
                                <h5 className="capitalize">Configuration</h5>
                            </div>

                            <Select
                                defaultValue={formik.values.configurations || []}
                                onChange={item => formik.setFieldValue('configurations', item)}
                                isMulti
                                className="rounded-lg"
                                options={configurationList}
                            />
                            {formik.errors &&
                                formik.touched.configurations &&
                                formik.errors.configurations && (
                                    <p className="text-red-600 mt-1">
                                        {formik.errors.configurations}
                                    </p>
                                )}
                        </AntD.Form.Item>
                    ) : (
                        <div className="w-48">
                            <Button
                                type="button"
                                label="Create New Configuration"
                                isLoading={false}
                                onClick={() => navigate('/configuration/create')}
                            />
                        </div>
                    )}
                </div>
                {/* the template */}
                <AntD.Form.Item
                    name="template"
                    className="flex flex-col w-[45%]"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                    ]}
                >
                    <div>
                        <h5 className="capitalize">Template</h5>
                    </div>
                    <AntD.Select
                        defaultValue={formik.values.template}
                        onChange={(value: string) => formik.setFieldValue('template', value)}
                        className="rounded-lg"
                        size={size}
                    >
                        {map(templates || [], (option: any) => (
                            <AntD.Select.Option value={option.value}>
                                {option.label}
                            </AntD.Select.Option>
                        ))}
                    </AntD.Select>
                    {formik.errors && formik.errors.template && formik.touched.template && (
                        <p className="text-red-600 mt-1">{formik.errors.template}</p>
                    )}
                </AntD.Form.Item>

                <div className="flex align-center items-center gap-8">
                    <div className="w-48">
                        <Button
                            type="button"
                            variant="outline"
                            label="Cancel"
                            isLoading={false}
                            onClick={() => changeStep(0)}
                        />
                    </div>
                    <div className="w-48">
                        <Button
                            variant="primary"
                            type="button"
                            label="Next"
                            disabled={Boolean(configurationList.length === 0)}
                            isLoading={false}
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigurationSelection
